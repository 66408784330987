.class-genericPolicy {
  width: 100vw;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.class-genericPolicy img {
  width: 100%;
}

.class-genericPolicy .class-container {
  position: relative;
}

.class-genericPolicy .class-title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 48px;
  font-weight: 600;
  color: rgba(37, 37, 37, 1);
  text-align: center;
}

.class-genericPolicy .class-content {
  max-width: 1120px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin: 80px 400px;
}

.class-genericPolicy .class-content .class-subTitle {
  color: rgba(80, 80, 80, 1);
  line-height: 26px;
  letter-spacing: 0.01em;
}

.class-genericPolicy .class-content .class-group {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.class-genericPolicy .class-content .class-group h2 {
  color: rgba(37, 37, 37, 1);
  font-size: 24px;
  line-height: 34px;
}

.class-genericPolicy .class-content .class-group .class-list {
  font-size: 16px;
  font-weight: 400;
  color: rgba(80, 80, 80, 1);
  line-height: 26px;
  letter-spacing: 0.01em;
}

.class-genericPolicy .class-content .class-group .class-list strong {
  color: rgba(37, 37, 37, 1);
  font-weight: 600;
}

.class-genericPolicy .class-content .class-group .class-list ul {
  margin-left: 20px;
}

.class-genericPolicy .class-content .class-group .class-list li {
  margin-left: 20px;
}

@media screen and (max-width: 1439px) {
  .class-genericPolicy .class-content {
    max-width: 768px;
    margin: 80px 200px;
  }
}

@media screen and (max-width: 1023px) {
  .class-genericPolicy .class-content {
    width: 100%;
    padding: 40px 96px;
  }

  .class-genericPolicy .class-content {
    margin: unset;
  }

  .class-genericPolicy .class-title {
    font-size: 40px;
  }

  .class-genericPolicy .class-content .class-subTitle {
    font-family: PingFang TC;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.01em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  .class-genericPolicy .class-content .class-group .class-list {
    font-family: PingFang TC;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.01em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
}

@media screen and (max-width: 767px) {
  .class-genericPolicy .class-content {
    width: 100%;
    padding: 32px;
    gap: 24px;
  }


  .class-genericPolicy .class-title {
    font-size: 32px;
  }

  .class-genericPolicy .class-content .class-subTitle {
    font-family: PingFang TC;
    font-size: 14px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0.01em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  .class-genericPolicy .class-content .class-group .class-list {
    font-family: PingFang TC;
    font-size: 14px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0.01em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
}