.app {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 20px;
  gap: 70px;
  margin-top: 140px;
}
.app > img {
  max-width: 100%;
}
.right-app {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 50px;
  color: white;
  width: 100%;
}
.right-app h2 {
  font-weight: 500;
  font-size: 40px;
}
.right-app h3 {
  font-weight: 500;
  font-size: 24px;
}
.btn-div {
  display: flex;
  justify-content: center;
  gap: 20px;
}
.btn-div button {
  min-width: 160px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding-inline: 30px;
  border-radius: 8px;
  height: 60px;
  border: none;
  cursor: pointer;
}

.btn-div button h4 {
  font-weight: 700;
  font-size: 16px;
  text-align: left;
}
.btn-div button p {
  font-weight: 700;
  font-size: 9px;
  margin-top: 0px;
  text-align: left;
}

.store-block {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.store-block .store-btn {
  display: flex;
  gap: 25px;
}

@media screen and (max-width: 1322px) {
  .app .btn-div {
    flex-direction: column;
  }
  .app button {
    width: 270px;
  }
}
@media screen and (max-width: 1080px) {
  .app {
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 40px;
  }
  .right-app {
    align-self: center;
    width: auto;
  }
  .app .btn-div {
    flex-direction: row;
  }
  .app button {
    width: auto;
  }
}
@media screen and (max-width: 630px) {
  .app {
    margin-top: 80px;
  }
  .app .btn-div {
    flex-direction: column;
    width: 100%;
  }
  .app .btn-div button {
    min-width: auto;
    gap: 0;
    padding-inline: 0;
  }

  .right-app {
    align-items: flex-start;
    width: 100%;
    padding-inline: 30px;
    gap: 20px;
  }
  .app button {
    width: 100%;
  }
  .right-app h2 {
    font-size: 25px;
  }
  .right-app h3 {
    font-size: 18px;
  }
  .store-block .store-btn{
    display: flex;
    flex-direction: column;
  }
}
