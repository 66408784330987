.provide {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 100px;
  width: 100%;
}

.provide-box {
  width: 100%;
  padding: 110px;
  max-width: 1200px;
  background-color: white;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  row-gap: 90px;
  max-height: 1000px;
  margin-top: 60px;
}
.provide-box hr {
  grid-column: 2/3;
  grid-row: 1/6;
  height: 100%;
  background-color: #d3d3d3;
  width: 1px;
  margin-inline: 80px;
}
.provide-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 50px;
}
.text-col {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
}
.text-col h3 {
  color: var(--gray);
  font-weight: 700;
  font-size: 24px;
}
.text-col p {
  color: var(--gray);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
@media screen and (max-width: 1036px) {
  .provide-box {
    display: flex;
    flex-direction: column;
    gap: 50px;
    align-items: center;
    margin-top: 0px;
    max-height: none;
    padding: 40px 20px;
  }
  .provide-box hr {
    display: none;
  }
  .provide-row {
    justify-content: flex-start;
    width: 100%;
    max-width: 300px;
  }
}
