/* @import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"); */
:root {
  --max-width: 1373px;
  --green: #059abb;
  --text: #505050;
  --gray: #7c7c7c;
  --dark: #32324d;
  --orange: #f4841e;
  --purple: #4146bd;
}
* {
  margin: 0;
  box-sizing: border-box;
  padding: 0;
  font-family: "Noto Sans", sans-serif;
}
html {
  scroll-behavior: smooth;
  scroll-padding-top: 100px;
}
body {
  overflow-x: hidden;
}
.container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  isolation: isolate;
}
.mt-header {
  margin-top: 100px;
}
.max-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: var(--max-width);
  padding-inline: 20px;
  flex-direction: column;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 66;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100vh;
}
.heading {
  color: var(--dark);
  font-weight: 500;
  text-align: center;
  font-size: 48px;
}
.sub {
  font-weight: 500;
  font-size: 20px;
  color: var(--gray);
  max-width: 600px;
  line-height: 30px;
  margin: 50px 0px;
  text-align: center;
}
@media screen and (max-width: 1036px) {
  .heading {
    font-size: 35px;
  }
  .sub {
    font-size: 18px;
    
  }
}
@media screen and (max-width: 450px) {
  .heading {
    font-size: 25px;
  }
  .sub {
    margin: 20px 0px;
    font-size: 16px;
    text-align: left;
  }
}
