.class-hero {
  width: 100vw;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.class-hero img {
  width: 100%;
}

.class-hero .class-container {
  width: 100%;
  max-width: var(--max-width);
  position: absolute;
  padding-inline: 20px;
}

.class-hero .class-content {
  display: flex;
  flex-direction: column;
  width: 446px;
}

.class-hero .class-content h1 {
  font-size: 48px;
  font-weight: 500;
  color: rgba(232, 122, 22, 1);
  margin-bottom: 20px;
}

.class-hero.class .class-content h1 {
  color: rgba(5, 154, 187, 1);
}

.class-hero .class-content p {
  font-size: 20px;
  font-weight: 500;
  color: rgba(232, 122, 22, 1);
  margin-bottom: 20px;
}

.class-hero.class .class-content p {
  color: rgba(5, 154, 187, 1);
}

.class-hero .class-content a {
  width: 160px;
  height: 50px;
  gap: 0px;
  border-radius: 8px;
  background-color: #fff;
  color: rgba(232, 122, 22, 1);
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.class-hero.class .class-content a {
  color: rgba(5, 154, 187, 1);
}

@media screen and (max-width: 1028px) {
  .class-hero {
    align-items: start;
  }

  .class-hero .class-content {
    width: 100%;
    align-items: center;
    padding-top: 36px;
  }
  
  .class-hero .class-content h1 {
    font-size: 40px;
    margin-bottom: 8px;
  }

  .class-hero .class-content p {
    font-size: 16px;
  }

  .class-hero .class-content a {
    width: 120px;
    height: 32px;
    font-size: 14px;
    border-radius: 4px;
  }
}

@media screen and (max-width: 640px) {
  .class-hero .class-content {
    /* padding-top: 19px; */
  }
  
  .class-hero .class-content h1 {
    font-size: 32px;
    margin-bottom: 4px;
  }

  .class-hero .class-content p {
    margin-bottom: 10px;
    width: 292px;
    text-align: center;
  }
}

@media screen and (max-width: 430px) {
  .class-hero h1 {
    font-size: 35px;
  }
}
